@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

@font-face {
   font-family: Fundamental;
   src: url("./assets/fonts/FundamentalBrigadeScvhlank.ttf");
}

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   user-select: none;
}

a {
   text-decoration: none;
}

.hover_btn_icon {
   transition: all 0.4s ease;
}

.hover_btn_icon:hover {
   transition: all 0.4s ease;
   opacity: 0.7;
}

.page_content {
   overflow: hidden;
}

.container {
   position: relative;
   max-width: 1440px;
   margin: 0 auto;
   padding: 0 48px;
}

#root, .App {
   min-height: 100dvh;
}

#root {
   background-color: rgba(149, 81, 22, 1);
}

.App {
   background: linear-gradient(50.21deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 130.625%);
}

@media screen and (max-width: 600px) {
   .container {
      padding: 0 15px;
   }
}
